<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">

            <CRow>
              <CCol sm="12">
                <CCard>
                  <CCardHeader>
                    <CCardTitle>Details of {{ customer.name }} </CCardTitle>
                  </CCardHeader>

                  <CCardBody>
                    <CRow>
                      <CCol sm="6">
                        <CInput
                          name="name"
                          label="Name"
                          placeholder="Enter the name of the customer"
                          v-model="customer.name"
                        />
                      </CCol>
                      <CCol sm="6">
                        <CSelect v-if="$store.getters.isAdmin"
                          placeholder="Select"
                          label="Location"
                          :options="locations"
                          :value.sync="customer.location_id"
                        />
                        <CInput v-if="!$store.getters.isAdmin"
                            type="hidden"
                            value='false'
                            v-model="customer.location_id"
                        />
                      </CCol>
                    </CRow>

                  </CCardBody>
                </CCard>

                <CCard>

                  <CCardHeader>
                    <CCardSubtitle>Address Information</CCardSubtitle>
                  </CCardHeader>

                  <CCardBody>

                    <CRow>
                      <CCol sm="4">
                        <CInput
                          name="address"
                          label="Address"
                          placeholder="Enter the address of the customer"
                          v-model="customer.address"
                        />
                      </CCol>
                      <CCol sm="4">
                        <CInput
                          label="Zip Code"
                          placeholder="Enter the zip code of the customer"
                          v-model="customer.zip_code"
                        />
                      </CCol>
                      <CCol sm="4">
                        <CInput
                          label="City"
                          placeholder="Enter the city of the customer"
                          v-model="customer.city"
                        />
                      </CCol>
                    </CRow>

                  </CCardBody>
                </CCard>

                <CCard>

                  <CCardHeader>
                    <CCardSubtitle>Contact Information</CCardSubtitle>
                  </CCardHeader>

                  <CCardBody>

                    <CRow>
                      <CCol sm="4">
                        <CInput
                          name="mail"
                          label="Mail"
                          placeholder="Enter the mail of the customer"
                          v-model="customer.mail"
                        />
                      </CCol>
                      <CCol sm="4">
                        <CInput
                          name="telephone"
                          label="Telephone"
                          placeholder="Enter the telephone of the customer"
                          v-model="customer.telephone"
                        />
                      </CCol>
                    </CRow>

                  </CCardBody>
                </CCard>
              </CCol>

              <CCol sm="12">
                <CButton variant="outline" type="submit" color="primary" @click="updateCustomer">
                  <CIcon name="cil-check-circle"/> Update
                </CButton>
                <CButton class="float-right" variant="outline" type="submit" color="danger" @click="deleteCustomer">
                  <CIcon name="cil-trash"/> Delete
                </CButton>
              </CCol>

            </CRow>

      </transition>
    </CCol>
  </CRow>
</template>

<script>

import CustomerAPI from '/app/src/api/customer.js'
import LocationAPI from '/app/src/api/location.js'

export default {
  name: 'Detail',
  data: () => {
    return {
      customer: {},
      customerAPI: new CustomerAPI(),
      locationAPI: new LocationAPI(),
      locations: [],
    }
  },
  created: function() {
    this.getCustomer(this.$route.params.id)
    this.getLocations()
  },
  methods: {
    getLocations: function() {
      this.$store.dispatch('loading')
      let self = this
      self.locationAPI.all()
      .then((result) => {
        this.$store.dispatch('stopLoading')
        for (let i = 0; i < result.length; i++) {
          self.locations.push({'label': result[i].name, 'value': result[i].id})
        }
      })
      .catch((error) => {
        this.$store.dispatch('stopLoading')
        console.log(error)
        this.$alert.show({type: 'danger', 'message': error})
      })
    },
    getCustomer: function(id) {
      this.$store.dispatch('loading');
      let self = this
      self.customerAPI.detail(id)
      .then((customer) => {
        this.$store.dispatch('stopLoading')
        console.log(customer)
        self.customer = customer
      })
      .catch((error) => {
        this.$store.dispatch('stopLoading')
        console.log(error)
        this.$alert.show({type: 'danger', 'message': error})
      })
    },
    updateCustomer: function() {
      this.$store.dispatch('loading')
      console.log(this.customer)
      let self = this
      this.customerAPI.update(self.customer)
      .then((result) => {
        this.$store.dispatch('stopLoading')
        self.$router.push({path: `/manager/customers/${self.customer.id}`})
        this.$alert.show({type: 'success', 'message': 'Customer has been updated'})
      })
      .catch((error) => {
        console.log(error)
        this.$alert.show({type: 'danger', 'message': error})
      })
    },

    deleteCustomer: function() {
      const params = {
        title: "Confirm",
        text: "Are you sure you want to delete?",
        onConfirm: () => {
          this.$store.dispatch('loading')
          this.customerAPI.delete(this.$route.params.id)
          .then((result) => {
            console.log(result)
            this.$store.dispatch('stopLoading')
            this.$router.push({path: '/manager/customers'})
          })
          .catch((error) => {
            console.log(error)
            this.$store.dispatch('stopLoading')
            this.$alert.show({type: 'danger', 'message': error})
          })
        }
      }
      this.$confirm.show(params)
    }
  }
}
</script>
